//Import React Library
import React, { Component } from 'react';
//Import Web3
import Web3 from 'web3'

//Import css code for project
import './App.css';

class Auth extends Component{

	//Connect to BlockChain using Web3
	async loadWeb3(){

		if (window.ethereum) {
	      window.web3 = new Web3(window.ethereum)
	      await window.ethereum.enable()
	    }
	    else if (window.web3) {
	      window.web3 = new Web3(window.web3.currentProvider)
	    }
	    else {
	      window.alert(' Unsuccessful Request whilst Loading your wallet please ensure MetaMask is running on your browser.')
	    }
		}
	


		async loadBlockChainData(){

			await this.loadWeb3()

			const web3 = window.web3



			//Grab account from metamask
			const accounts = await web3.eth.getAccounts()
			//set the account to the current state of account variable
			this.setState({ account: accounts[0]})

			
			this.setState({ active: true})
			var el = document.getElementById('myButton');
				
			el.firstChild.data = this.state.account.substring(0,15) + '...';

		}

	constructor(props) {
	    super(props)
	    this.state = {
	    	account: '', 
	    	active: false,	    	
     }
  }
	render(){


		return(

			<div>

			<div className="flex flex-col items-center justify-center" >
      		<button id="myButton" onClick={() => this.loadBlockChainData()}  className="btn custom-btn bordered mt-1" target="_blank">Connect your Wallet</button>
      			   
    		</div>

    		</div>

			);

	}
}



export default Auth;